<template> 
   <div>
        <template-form v-if="!isLoading"  :initialFields="fields" :onSubmit="onSubmit" :update="true">
            <template v-slot:notification>
                <message :type="ntf.type" :status="ntf.status" :message="ntf.message" />
            </template>
		</template-form>
	</div>
</template>
 
<script>
    import Form from './Form.vue';
    
    //import Message from '../../../../../components/Message.vue';
    //import { defaultErrors } from "../../../../../helpers";
    export default {
        props: { 
            update: {
                type: Boolean,
                default: false
            }
        },
/*         data : function() {
            return {
                isLoading: false,
                fields: {
                    name: '',
                    address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            };
        }, */
        data() {
            return {
                isLoading: false,
                fields: {
                    supp_code: '',
                    supp_address: '',
                    phone: ''
                },
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            }
        },
        components: {
            'template-form': Form//, Message
        },
        beforeMount() {
            this.getData(this.$route.params.id)
            console.log('before mount di edit');
            console.log(this.fields);
        },
        methods: {
            async getData(id) {
                this.isLoading = true;
                try {
                    //const response = await axios.get('/api/product/'+id);
                    const response = await this.apiGets(`items/`+id);
                    //const response = await axios.get(`suppliers/`+id);
                    this.fields = response.data.data;
                    console.log('di get data');
                    console.log(this.fields);
                } catch (error) {
                    /* this.$notify({
                        type: 'error',
                        title: error.message + '!',
                        text: defaultErrors(error.response.data.errors)
                    }) */
                    console.log(error);     
                }
                //console.log(this.fields);    
                this.isLoading = false;
            },
            async onSubmit(fields) {
                this.isLoading = true;
                    console.log(fields);          
                    this.$toastr.s("Success", "Hello world");          
                    try {
                        const response = await this.apiPosts(`items/update/${this.$route.params.id}`, fields);
                        console.log(response);                        
                         this.$router.push({
                            name: 'supplier'
                        });
                    } catch (error) {
                        console.log(error);                                                
                    }
                    this.isLoading = false;
            },
            //defaultFields
                 
            }
    }
</script>